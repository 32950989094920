import React from 'react'
import { Layout, SEO } from 'components/common'
import Header from 'components/theme/Header'
import { Footer } from 'components/theme'
import { ModalContainer } from 'reoverlay'
import Container from 'components/common/Container'
import { usePartnerInfo } from 'hooks/usePartnerData'

const FourOhFour = () => {
  const { contact } = usePartnerInfo()

  return (
    <>
      <Layout>
        <SEO title="404: Not found" location="/404" />
        <Header />
        <Container constraints="narrow" className="fourOfour">
          <h1>404</h1>
          <p className="subhead">Something's wrong.</p>
          <p>
            You just hit a page that doesn&#39;t exist. Just <a href="/">go back</a> or let's us now how we can help you
            contacting the Help Center at <a href={`mailto:${contact[0].email1}`}>{contact[0].email1}.</a>.
          </p>
        </Container>
      </Layout>
      <ModalContainer />
      <Footer />
    </>
  )
}

export default FourOhFour
